import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchAllGoogleSheets, fetchGoogleSheetsByOffice } from 'redux/google-sheets/google-sheets.action';

interface GoogleSheetsState {
  sheets: Array<any>;
  formattedSheets: Array<Record<string, string>>;
  isLoading: boolean;
  error: string | null;
  officeName: string;
  adminSheetsByOffice: Record<string, Record<string, string>[]>;
}

const initialState: GoogleSheetsState = {
  sheets: [],
  formattedSheets: [],
  isLoading: false,
  error: null,
  officeName: '',
  adminSheetsByOffice: {},
};

const googleSheetsSlice = createSlice({
  name: 'googleSheets',
  initialState,
  reducers: {
    updateSheetsFromSocket: (state, action: PayloadAction<string[][]>) => {
      const newSheets = action.payload;

      if (newSheets.length === 0) {
        state.sheets = [];
        state.formattedSheets = [];
        return;
      }

      const keys: string[] = newSheets[0];
      const valuesArray: string[][] = newSheets.slice(1);

      state.sheets = newSheets;
      state.formattedSheets = valuesArray.map((row) =>
        keys.reduce<Record<string, string>>((acc, key, index) => {
          acc[key] = row[index] || '';
          return acc;
        }, {}),
      );
    },

    // updateAllSheetsFromSocket: (state, action: PayloadAction<{ officeName: string; data: any[][] }>) => {
    //   const { officeName, data } = action.payload;
    //   state.sheetsByOffice[officeName] = data;
    // },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchGoogleSheets.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   })
    //   .addCase(fetchGoogleSheets.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.sheets = action.payload;
    //
    //     if (action.payload.length > 1) {
    //       const keys: string[] = action.payload[0];
    //       const valuesArray: string[][] = action.payload.slice(1);
    //
    //       state.formattedSheets = valuesArray.map((row) =>
    //         keys.reduce<Record<string, string>>((acc, key, index) => {
    //           acc[key] = row[index] || '';
    //           return acc;
    //         }, {}),
    //       );
    //     } else {
    //       state.formattedSheets = [];
    //     }
    //   })
    //   .addCase(fetchGoogleSheets.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.payload as string;
    //   });

    builder
      .addCase(fetchAllGoogleSheets.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchAllGoogleSheets.fulfilled,
        (state, action: PayloadAction<{ data: { officeName: string; data: string[][] }[] }>) => {
          state.isLoading = false;
          state.sheets = action.payload.data; // ✅ Сохраняем весь массив офисов

          state.adminSheetsByOffice = action.payload.data.reduce<Record<string, Record<string, string>[]>>(
            (acc, { officeName, data }: { officeName: string; data: string[][] }) => {
              if (data.length > 1) {
                const keys: string[] = data[0]; // ✅ Заголовки таблицы
                const valuesArray: string[][] = data.slice(1); // ✅ Строки без заголовков

                acc[officeName] = valuesArray.map((row) =>
                  keys.reduce<Record<string, string>>((rowAcc, key, index) => {
                    rowAcc[key] = row[index] || ''; // ✅ Создаем объект из строки
                    return rowAcc;
                  }, {}),
                );
              } else {
                acc[officeName] = [];
              }

              return acc;
            },
            {} as Record<string, Record<string, string>[]>, // ✅ Используем объект для хранения данных по офисам
          );
        },
      )

      .addCase(fetchAllGoogleSheets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchGoogleSheetsByOffice.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchGoogleSheetsByOffice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sheets = action.payload.data;
        state.officeName = action.payload.officeName;

        if (action.payload.data.length > 1) {
          const keys: string[] = action.payload.data[0];
          const valuesArray: string[][] = action.payload.data.slice(1);

          state.formattedSheets = valuesArray.map((row) =>
            keys.reduce<Record<string, string>>((acc, key, index) => {
              acc[key] = row[index] || '';
              return acc;
            }, {}),
          );
        } else {
          state.formattedSheets = [];
        }
      })

      .addCase(fetchGoogleSheetsByOffice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { updateSheetsFromSocket } = googleSheetsSlice.actions;

export default googleSheetsSlice.reducer;
