import axios from 'api';

const url = '/api/google/sheets';
const deleteUrl = '/api/google/delete-row';
const getAllSheets = '/api/google/get-all-sheets';
const getSheetsByOffice = '/api/google/fetch-by-office';

const GoogleSheetsAPI = {
  deleteRowFromGoogleSheets: (data: { officeName: string; sheetId: number; rowIndex: number | null }) =>
    axios.post(deleteUrl, data),

  getAllSheets: () => axios.get(getAllSheets),

  getSheetsByOffice: (officeName: string, range: string) =>
    axios.get(getSheetsByOffice, {
      params: { officeName, range },
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default GoogleSheetsAPI;
