import io, { Socket } from 'socket.io-client';

export enum SocketNamespace {
  partners = 'partners',
  notification = 'notification',
  googleSheets = 'google-sheets',
}

const connections: Record<string, Socket | null> = {
  default: null,
};

export const getSocket = (id: string, namespace?: SocketNamespace): Socket => {
  const HOST = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:5001';
  const hasNamespaceSocket = namespace && Object.prototype.hasOwnProperty.call(connections, namespace);

  if (hasNamespaceSocket) {
    return connections[namespace] as Socket;
  }
  const socketOptions = {
    transports: ['websocket'],
    auth: { id: id },
  };
  if (namespace && !hasNamespaceSocket) {
    const URI = namespace ? `${HOST}/${namespace}` : HOST;
    connections[namespace] = io(URI, socketOptions);
    return connections[namespace] as Socket;
  } else {
    if (!connections['default']) {
      connections['default'] = io(HOST, socketOptions);
      return connections['default'];
    } else return connections['default'];
  }
};
