import { IUserAssignee } from 'redux/user/user.types';

export enum IFetchingStatuses {
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export interface LoadingStatus {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface IFormatLogs {
  id: string;
  userName: string;
  clientName: string;
  action: string;
  changes: Record<string, any>[] | string;
  update: Date | string;
}

export type IStatuses = IFetchingStatuses | null;

export interface IClientFilters {
  assignee?: string;
  client?: string;
  clientId?: string;
  deadline?: string;
  email?: string;
  event?: string;
  mobile?: string;
  office?: string;
  tags?: Array<any>;
  [key: string]: string | undefined | Array<any>;
}
export type IFilters = Record<string, any> | null;
export type ISorting = Record<string, any> | null;

export interface ISortPayload {
  id: string;
  desc: boolean;
}

export interface IFilterProps {
  applyFilters: (value: Record<string, string>) => void;
  hasFilters: boolean;
}

export interface EntityParsedResponse<T> {
  total: number;
  result: Array<T>;
}

export interface IComment {
  _id: string;
  author: IUserAssignee;
  fullName?: string;
  note: string;
  date: Date;
}

export interface BaseOptionItem<T> {
  title: string;
  value: T;
}

export interface CheckBoxAutocompleteOptionItem<T> {
  title: string;
  value: T;
  isActive: boolean;
  officeId?: string;
}

export interface OptionItem<T> extends BaseOptionItem<T> {
  type: string;
}

export interface IUserAutocompleteOptionItem {
  _id: string;
  name: string;
  surname: string;
  patronymic: string;
  role: string;
  avatar: string;
  registered_by: string;
}

export interface MockData {
  eventsType: Array<OptionItem<string>>;
  eventsTypeForAnalytic: Array<OptionItem<string>>;
  eventsTypeForHR: Array<OptionItem<string>>;
  salesStatisticEventType: Array<OptionItem<string>>;
  hrStatisticEventType: Array<OptionItem<string>>;
  analyticStatisticEventType: Array<OptionItem<string>>;
  statusType: Array<OptionItem<string>>;
  genderTypes: Array<OptionItem<string>>;
  taskStatus: Array<OptionItem<string>>;
  advertiseSource: Array<OptionItem<number>>;
  taskPriority: Array<OptionItem<number>>;
  roles: Record<string, string>;
  roleTypes: Array<Omit<OptionItem<string>, 'type'>>;
}

export interface ModalData<T> {}

export interface BaseModalProps extends MockData {
  classes: Record<string, string>;
  close: () => void;
}

export interface EmployeeRewards {
  withReward: number;
  amount: number;
}

export enum HTTP_METHODS {
  get = 'GET',
  post = 'POST',
  patch = 'PATCH',
  put = 'PUT',
  delete = 'DELETE',
}

export enum AsyncActionResultStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Pending = 'pending',
}

export type OptionTypes<T = string> = { value: T; label: string };

export interface IOperation {
  _id: string;
  createdAt: Date;
  type: string;
  totalAmount: number;
  clientId?: string;
  brokerClientId?: string;
}

export const EVENTS = {
  connect: 'connect',
  registerSession: 'register-session',
  sessionRegistered: 'session-registered',
  joinRoom: 'join-room',
  userJoinRoom: 'user-join-room',
  update: 'update',
};

// export const officess_SpeadSheets: Record<string, string> = {
//   Analysts: '1DttMyaKCur-5CSuk4jFX2jskj7ZZxFQy44EaIUwdXvU',
//   Office: '1DttMyaKCur-5CSuk4jFX2jskj7ZZxFQy44EaIUwdXvU',
//   // Office: '1VyzbAFHEO1QN8qwyUoWb0VHPLKom4AMdEzyGId-nvuA',
//   MSK: '1YlcyZPidgs4Fd3Tcch3dURs8gCpPpZd1iprmA0RbXpo',
// };
// export function GetOfficesSpreadSheetId(OfficeName: string): string {
//   console.log(OfficeName);
//   return officess_SpeadSheets[OfficeName];
// }
