import axios from 'api';
import { LeadDto } from 'redux/create-lead/create-lead.type';

const url = 'api/create-lead';

const CreateLeadAPI = {
  getAll: () => axios.get(url),
  create: (data: LeadDto) => axios.post(url, data),
  getById: (id: string) => axios.get(`${url}/${id}`),
};

export default CreateLeadAPI;
