import React, { useState } from 'react';

import { Box, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { BaseModalProps, IComment } from '@/types/common';
import { modalDataSelector } from 'redux/app/app.selectors';
import { patchEvent } from 'redux/event/event.actions';
import { eventsStatusSelector } from 'redux/event/event.selector';
import { useAppSelector } from 'redux/hooks';
import { myProfileFullName } from 'redux/profile/profile.selectors';

interface CreateCommentModalData {
  comments: Array<IComment>;
  userId: string;
  eventId: string;
  edit?: boolean;
  commentId: string;
}

const CreateComment = (props: BaseModalProps) => {
  const dispatch = useDispatch();
  const { comments, userId, eventId, commentId, edit } = useAppSelector<CreateCommentModalData>(modalDataSelector);
  const { isLoading } = useAppSelector(eventsStatusSelector);
  const [comment, setComment] = useState('');
  const myFullName = useAppSelector(myProfileFullName);

  const addComment = () => {
    const message = {
      author: userId,
      fullName: myFullName && myFullName,
      note: comment,
      date: new Date(),
    } as unknown as IComment;
    dispatch(
      patchEvent({
        event: {
          comments: [...comments, message],
          updatedAt: new Date(),
          _id: eventId,
        },
      }),
    );
  };
  const submit = () => {
    edit ? updateComment() : addComment();
  };

  const updateComment = () => {
    const newComments = [...comments];
    const editIndex = newComments.findIndex((note) => note._id === commentId);
    if (editIndex !== -1) {
      newComments[editIndex] = { ...newComments[editIndex], note: comment, date: new Date() };
      dispatch(
        patchEvent({
          event: {
            comments: newComments,
            updatedAt: new Date(),
            _id: eventId,
          },
        }),
      );
    }
  };

  const handleCommentOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  return (
    <Box>
      <Grid container justify={'space-around'}>
        <Grid className={props.classes.grid} xs={12}>
          <TextField
            required
            variant="outlined"
            multiline={true}
            rowsMax={15}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '20px',
            }}
            inputProps={{
              value: comment,
              rows: 15,
              onChange: handleCommentOnChange,
              name: 'note',
              placeholder: 'Ваш комментарий',
            }}
          />
        </Grid>
      </Grid>

      <Box padding={'10px 0'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button
          disabled={!comment || isLoading}
          endIcon={isLoading && <CircularProgress size={15} color="secondary" />}
          onClick={submit}
          size={'small'}
          variant={'contained'}
        >
          {edit ? 'Изменить комментарий' : 'Сохранить комментарий'}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateComment;
