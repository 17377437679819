import React, { useCallback, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button, CircularProgress, Fade, Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ru from 'date-fns/locale/ru';
import { useRouter } from 'next/router';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { BaseModalProps, IUserAutocompleteOptionItem, OptionItem } from '@/types/common';
import { getClientsForAutocomplete } from '@/utils/axiosHelpers';
import { composeClientName, composeUserName, getListAssignees } from '@/utils/helper';
import { _userListForClientAutocomplete, getClientDataForActionModal } from '@/utils/selectVariants';
import { getUserDataForActionModals } from '@/utils/utils';
import { ANALYTIC, DEVELOPMENTDIRECTOR, HEADOFATTRACTIONDEPARTMENT, HR, SALESHEADMANAGER } from '@/variables/management';
import { showModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { updateClient } from 'redux/client/client.actions';
import { currentClientAssigneeSelector, currentClientSelector } from 'redux/client/client.selectors';
import { IClient } from 'redux/client/client.types';
import { patchEvent, postEvent } from 'redux/event/event.actions';
import { eventsStatusSelector } from 'redux/event/event.selector';
import { CreateEventRequest, EventTypesLead, IEvent } from 'redux/event/event.types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { viewingAccessByRole } from 'redux/managment/management.selectors';
import { officesAutoCompleteOptions } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';
import { isHeadOfAttractionDepartmentRole, profileSelector, roleSelector } from 'redux/profile/profile.selectors';
import { allowChangeEventDeadlineSelector, currentUserSelector, userListSelector } from 'redux/user/user.selectors';
import { useGetUsersByRoleQuery } from 'redux/user/user.service';
import { IUser, IUserAssignee, IUserRoles } from 'redux/user/user.types';

import LazyAutocomplete from '../../LazyAutocomplete';

interface CreateEventModalData extends IEvent {
  forMultipleClients?: boolean;
  clientIds: Array<string>;
}

const CreateEvent = (props: BaseModalProps) => {
  const { statusType, eventsType, eventsTypeForAnalytic, eventsTypeForHR } = props;

  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isLoading } = useAppSelector(eventsStatusSelector);
  const currentClient = useAppSelector(currentClientSelector);
  const currentEmployee = useAppSelector(currentUserSelector);
  const eventData = useAppSelector<CreateEventModalData>(modalDataSelector);
  const isHeadOfAttractionDepartment = useAppSelector(isHeadOfAttractionDepartmentRole);
  const myProfileData = useAppSelector(profileSelector);
  const role = useAppSelector(roleSelector);
  const currentClientAssignee = useAppSelector(currentClientAssigneeSelector);
  const usersInOfficeListData = useAppSelector(userListSelector);
  const allowChangeEventDeadline = useAppSelector(allowChangeEventDeadlineSelector);
  const officeList = useAppSelector(officesAutoCompleteOptions);
  const { highAccess } = useAppSelector(viewingAccessByRole);
  const { data: managersList } = useGetUsersByRoleQuery(IUserRoles.manager);
  const responsePersons = _userListForClientAutocomplete(usersInOfficeListData);

  if (managersList && isHeadOfAttractionDepartment) {
    const managers = _userListForClientAutocomplete(managersList);
    responsePersons.push(...managers);
  }

  const allowDateForChangeEvent = () => {
    return eventData ? allowChangeEventDeadline : true;
  };
  const clientPageId = router.pathname === '/clients/[pid]' ? router.query.pid : null;
  const userPageId = router.pathname === '/users/[pid]' ? router.query.pid : null;

  const currentClientName = (): string => composeClientName(currentClient);
  const currentUserName = (): string => composeUserName(currentEmployee);

  const defaultValues = {
    title: eventData?.title || '',
    refill: eventData?.refill || null,
    status: eventData?.status || '',
    assignee: eventData?.assignee
      ? eventData?.assignee
      : userPageId
      ? getUserDataForActionModals(currentEmployee as IUser)
      : getUserDataForActionModals(myProfileData),
    client: eventData?.client || '',
    description: eventData?.description || '',
    deadline: eventData?.deadline ? new Date(eventData?.deadline) : new Date(),
    clarification: '',
    office: eventData?.office ? eventData?.office : userPageId ? currentEmployee?.office : myProfileData.office || '',
  };

  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [event, setEvent] = useState<any>(defaultValues);
  const isRefill = ['Открытие счета', 'Пополнение счета'].includes(event?.title);

  const getCurrentEvents: () => Array<OptionItem<string>> = useCallback(() => {
    if (role === ANALYTIC || event?.assignee?.role === ANALYTIC) {
      return eventsTypeForAnalytic;
    } else if (role === HR || event?.assignee?.role === HR) {
      return eventsTypeForHR;
    } else if (role === HEADOFATTRACTIONDEPARTMENT) {
      return [...eventsType.filter((evType) => ['Регистрация', 'Консультация', 'Лекция', 'Звонок'].includes(evType.title))];
    } else if (role === DEVELOPMENTDIRECTOR || role === SALESHEADMANAGER) {
      return [
        ...eventsTypeForHR,
        ...eventsType.filter((evType) => !['Стажировка', 'Регистрация', 'Звонок'].includes(evType.title)),
      ];
    } else {
      return eventsType;
    }
  }, [event, role]);

  useEffect(() => {
    setEvent({
      ...event,
      title: eventData?.title || '',
      deadline: eventData?.deadline,
      refill: eventData?.refill || 0,
      client: eventData?.client,
      status: eventData?.status || '',
      assignee: userPageId
        ? getUserDataForActionModals(currentEmployee as IUser)
        : eventData?.assignee
        ? eventData?.assignee
        : getUserDataForActionModals(myProfileData),
      description: eventData?.description || '',
      clarification: '',
      office: eventData?.office ? eventData?.office : userPageId ? currentEmployee?.office : myProfileData.office,
    });
  }, [eventData]);

  function changeHandler<T>(e: React.ChangeEvent<{}>, option: OptionItem<T>) {
    if (!option?.type) return;
    setEvent({ ...event, [option.type]: option.value });
    setValue(option.type, option.value);
  }

  const hasMinimumLetters = (value: string): boolean | string => {
    const letters = value.match(/[a-zA-Zа-яА-Я]/g);
    return (letters && letters.length >= 5) || 'Вводите полный комментарий!';
  };

  const submit = (data: Partial<IEvent>) => {
    let newEvent: CreateEventRequest = {
      event: {
        title: data.title,
        description: data.description,
        clarification: data.clarification,
        deadline: data.deadline,
        assignee: data.assignee || event.assignee,
        status: data.status,
        office: data?.office || event.office,
        ...(eventData ? {} : { type: EventTypesLead.consultation }),
      },
      client: clientPageId ? getClientDataForActionModal(currentClient as IClient) : (data.client as IClient),
    };

    if (eventData?.forMultipleClients && eventData.clientIds) {
      newEvent.clientIds = eventData.clientIds;
    }

    if (!!data.refill) {
      newEvent.event.refill = +data.refill;
    }

    const isInternshipEnd = newEvent.event.title === 'Конец стажировки' && newEvent.event.status === 'Выполнено';

    const isClientAssigneeToChange =
      role !== IUserRoles.analytic &&
      currentClient &&
      (currentClient?.assignee as IUserAssignee)?._id !== newEvent.event.assignee?._id;

    if (!!eventData && !eventData.forMultipleClients) {
      dispatch(
        patchEvent(
          { ...newEvent, event: { ...newEvent.event, updatedAt: new Date(), _id: eventData._id } } as Partial<CreateEventRequest>,
          !isInternshipEnd,
        ),
      );
    } else {
      dispatch(postEvent(newEvent, !isInternshipEnd));
    }
    if (isClientAssigneeToChange && currentClient) {
      dispatch(
        updateClient({
          client: {
            _id: currentClient?._id as string,
            assignee: newEvent.event.assignee as IUserAssignee,
            office: currentClient ? currentClient.office : (newEvent?.event.office as IOffice),
            list_assignees: getListAssignees(newEvent.event, currentClient),
          },
        }),
      );
    }

    if (isInternshipEnd) {
      const userData = {
        mobile: currentClient?.mobile,
        email: currentClient?.email,
        personalData: {
          name: currentClient?.name,
          surname: currentClient?.surname,
          patronymic: currentClient?.patronymic,
          gender: currentClient?.gender,
        },
        role: 'applicant',
        office: currentClient?.office,
        registered_by: currentClient?.registered_by,
      };

      dispatch(showModal({ name: 'createEmployee', type: 'modal', data: { currentEmployee: userData } }));
    }
  };

  const isCreation = !!eventData && !eventData.forMultipleClients;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2} justifyContent={'space-around'}>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Autocomplete
                {...props}
                options={getCurrentEvents()}
                size={'small'}
                getOptionLabel={(option) => (option ? option.title : '')}
                getOptionSelected={(option) => option.value === event.title}
                onChange={(e, newValue: OptionItem<string> | null) => {
                  newValue && changeHandler(e, newValue);
                }}
                value={getCurrentEvents().find((option) => option.value === event.title) || null}
                renderInput={(params) => (
                  <TextField {...params} label="Тип события" error={!!errors.title} required variant="outlined" />
                )}
              />
            )}
          />
        </Grid>
        {isRefill && (
          <Fade in={isRefill}>
            <Grid item xs={12}>
              <Controller
                name={'refill'}
                rules={{ required: isRefill }}
                control={control}
                render={(props) => (
                  <TextField
                    {...props}
                    label={'Сумма в USD'}
                    error={!!errors.refill}
                    type={'number'}
                    fullWidth
                    size={'small'}
                    variant={'outlined'}
                  />
                )}
              />
            </Grid>
          </Fade>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="deadline"
            render={(props) => (
              <ReactDatePicker
                className="input"
                locale={ru}
                onChange={(e) => {
                  setValue('deadline', e);
                }}
                selected={props.value}
                showTimeSelect
                filterDate={allowDateForChangeEvent}
                timeFormat="HH:mm"
                timeIntervals={10}
                dateFormat="MM/dd/yyyy HH:mm "
                customInput={
                  <TextField
                    label="Выберите дату и время"
                    error={!!errors.deadline}
                    size={'small'}
                    fullWidth={true}
                    variant="outlined"
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="status"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Autocomplete
                {...props}
                options={statusType}
                size={'small'}
                getOptionLabel={(option) => (option ? option.title : '')}
                getOptionSelected={(option) => option.value === event[option.type]}
                onChange={(e, newValue: OptionItem<string> | null) => {
                  newValue && changeHandler(e, newValue);
                }}
                value={statusType.find((status) => status.value === event.status) || null}
                renderInput={(params) => (
                  <TextField {...params} label="Статус" required error={!!errors.status} variant="outlined" />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {userPageId ? (
            <TextField
              fullWidth={true}
              variant={'outlined'}
              size={'small'}
              inputProps={{
                value: currentUserName(),
                disables: true,
              }}
            />
          ) : (
            <Controller
              name="assignee"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={responsePersons}
                  size={'small'}
                  getOptionLabel={(option) => (option ? option.title : '')}
                  getOptionSelected={(option) => option.value._id === event[option.type]._id}
                  onChange={(e, newValue: OptionItem<IUserAutocompleteOptionItem> | null) => {
                    newValue && changeHandler(e, newValue);
                  }}
                  // @ts-ignore
                  value={responsePersons.find((person) => person.value._id === event.assignee._id) || null}
                  renderInput={(params) => (
                    <TextField {...params} label="Ответственный" required error={!!errors.assignee} variant="outlined" />
                  )}
                />
              )}
            />
          )}
        </Grid>
        {highAccess && (
          <Grid item xs={12}>
            <Controller
              name="office"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={officeList}
                  size={'small'}
                  getOptionLabel={(option) => (option ? option.title : '')}
                  getOptionSelected={(option) => option.value._id === event[option.type]._id}
                  onChange={(e, newValue: OptionItem<IOffice> | null) => {
                    newValue && changeHandler(e, newValue);
                  }}
                  value={officeList.find((office) => office.value._id === event?.office?._id) || null}
                  renderInput={(params) => (
                    <TextField {...params} required label="Офис" error={!!errors.office} variant="outlined" />
                  )}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label={'Создатель события'}
            fullWidth={true}
            variant={'outlined'}
            size={'small'}
            inputProps={{
              value: composeClientName(myProfileData?.personalData),
              disables: true,
            }}
          />
        </Grid>
        {!eventData?.forMultipleClients && (
          <Grid item xs={12}>
            {clientPageId ? (
              <TextField
                fullWidth={true}
                variant={'outlined'}
                size={'small'}
                label={'Клиент'}
                inputProps={{
                  value: currentClientName(),
                  disables: true,
                }}
              />
            ) : (
              <Controller
                name="client"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <LazyAutocomplete
                    {...props}
                    label="Клиент"
                    size={'small'}
                    defaultValue={event.client ? `${event.client.name} ${event.client.surname}` : ''}
                    fetchData={getClientsForAutocomplete}
                    entity={'client'}
                    changeOptions={changeHandler}
                    error={!!errors.client}
                  />
                )}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name={'description'}
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label={'Описание'}
                error={!!errors.description}
                multiline
                minRows={4}
                maxRows={5}
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>
        {isCreation && (
          <Grid item xs={12}>
            <Controller
              name={'clarification'}
              rules={{ required: 'Это поле обязательно!', minLength: 10, validate: hasMinimumLetters }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label={'Причина редактирования'}
                  error={!!errors.clarification}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                  maxRows={6}
                  variant={'outlined'}
                  helperText={errors.clarification && <Typography>{String(errors.clarification.message)}</Typography>}
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
        <Button
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} color="secondary" />}
          fullWidth
          variant={'contained'}
          size={'large'}
          onClick={handleSubmit(submit)}
          color={'primary'}
        >
          Сохранить
        </Button>
      </Box>
    </form>
  );
};

export default CreateEvent;
