import { useState, useEffect } from 'react';

import { Socket } from 'socket.io-client';

import { EVENTS } from '@/types/common';
import { getSocket, SocketNamespace } from '@/utils/getSocket';
import { updateSheetsFromSocket } from 'redux/google-sheets/google-sheets.reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { myIdSelector } from 'redux/profile/profile.selectors';

type GoogleSheetUpdate = {
  sheetName: string;
  row: number;
  data: any[];
};

export function useWebSocketGoogleSheets() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const id = useAppSelector(myIdSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const socketIo = getSocket(id, SocketNamespace.googleSheets);
    setSocket(socketIo);

    socketIo.on(EVENTS.connect, () => {
      console.log('✅ Подключено к WebSocket Google Sheets');
      socketIo.emit(EVENTS.joinRoom);
    });

    socketIo.on('errorMessage', (error: any) => {
      console.error('❌ Ошибка WebSocket:', error);
    });

    socketIo.on('disconnect', () => {
      console.log('❌ Отключено от WebSocket Google Sheets');
    });

    socketIo.on('update', (data: GoogleSheetUpdate) => {
      console.log('📌 Обновление из Google Sheets:', data);
      dispatch(updateSheetsFromSocket(data.data));
    });
  }, [id]);

  return null;
}
