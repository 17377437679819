import React, { useEffect, useState } from 'react';

import { Grid, TextField, Button, Box, CircularProgress, Modal } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// eslint-disable-next-line import/order
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';

import { BaseModalProps, IUserAutocompleteOptionItem, OptionItem } from '@/types/common';
import { _userListForClientAutocomplete } from '@/utils/selectVariants';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { fetchLeads, deleteLead } from 'redux/create-lead/create-lead.action';
import { createLeadEvent } from 'redux/create-lead-event/create-lead-event.action';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { isHeadOfAttractionDepartmentRole } from 'redux/profile/profile.selectors';
import { userListSelector } from 'redux/user/user.selectors';
import { useGetUsersByRoleQuery } from 'redux/user/user.service';
import { IUserRoles } from 'redux/user/user.types';

interface IResponsible {
  _id: string;
  name: string;
  surname: string;
}
interface ICreator {
  _id: string;
  name: string;
  surname: string;
}

// Описываем структуру eventData, которую возвращает modalDataSelector
export interface ILeadEvent {
  type?: string;
  date: Date;
  responsible: IResponsible; // или ID пользователя
  creator: ICreator;
  status?: string;
  client?: string; // например, имя или ID клиента
  comment?: string;
  assigneeId?: string;
  author?: string;
  clientName?: string;
  clientId: string;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>; // Здесь описан setLoading
  indexRow: number;
}

interface CreateLeadEventModalProps extends BaseModalProps {
  defaultData?: Partial<ILeadEvent>;
  leadName?: string; // Имя лида, если нужно передать его как клиент
}

// type
const eventTypes = [
  { value: 'первичный контакт', label: 'Первичный контакт' },
  { value: 'встреча', label: 'Встреча' },
  { value: 'предложение отправлено', label: 'Предложение отправлено' },
  { value: 'демонстрация продукта', label: 'Демонстрация продукта' },
  { value: 'обсуждение договора', label: 'Обсуждение договора' },
  { value: 'сделка заключена', label: 'Сделка заключена' },
  { value: 'последующий контакт', label: 'Последующий контакт' },
];

// status
const statuses = [
  { value: 'Просрочено', label: 'Просрочено' },
  { value: 'Запланировано', label: 'Запланировано' },
  { value: 'Выполнено', label: 'Выполнено' },
  { value: 'Обработано', label: 'Обработано' },
];

const CreateLeadEventModal: React.FC<CreateLeadEventModalProps> = ({ defaultData = {}, leadName, ...props }) => {
  // Данные события из редакса (если нужно)
  const eventData = useAppSelector(modalDataSelector) as ILeadEvent;

  // Подготавливаем значения по умолчанию
  const defaultValues: Omit<ILeadEvent, 'setLoading'> = {
    type: eventData?.type || defaultData?.type || '',
    date: eventData?.date || defaultData?.date || new Date(),
    responsible: eventData?.responsible || defaultData?.responsible || undefined,
    creator: eventData?.creator || defaultData?.creator || '',
    status: eventData?.status || defaultData?.status || '',
    // Если у нас нет eventData.client, берём leadName или пустую строку
    client: eventData?.client || defaultData?.client || leadName || '',
    comment: eventData?.comment || defaultData?.comment || '',
    clientId: eventData.clientId || defaultData.clientId || '',
    indexRow: eventData.indexRow || defaultData.indexRow || 0,
  };

  // Регистрируем форму
  const { control, handleSubmit, setValue, errors } = useForm<ILeadEvent>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  console.log(defaultValues.indexRow);

  // Получаем список пользователей (ответственных)
  const usersInOfficeListData = useAppSelector(userListSelector);
  const responsePersons = _userListForClientAutocomplete(usersInOfficeListData);

  // Проверка на роль
  const isHeadOfAttractionDepartment = useAppSelector(isHeadOfAttractionDepartmentRole);
  const { data: managersList } = useGetUsersByRoleQuery(IUserRoles.manager);
  const spreadsheetId = '1DttMyaKCur-5CSuk4jFX2jskj7ZZxFQy44EaIUwdXvU';

  // Если это руководитель отдела, то добавляем менеджеров в общую выборку
  if (managersList && isHeadOfAttractionDepartment) {
    const managers = _userListForClientAutocomplete(managersList);
    responsePersons.push(...managers);
  }

  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Обработчик отправки формы
  const submitHandler = async (data: ILeadEvent) => {
    const formData = {
      type: data.type,
      date: data.date.toISOString(),
      assigneeId: data.responsible._id || '',
      author: `${data.responsible._id}` || '',
      status: data.status,
      clientName: data.client,
      comment: data.comment,
    };

    setIsSubmitting(true);

    try {
      if (eventData.setLoading) {
        eventData.setLoading(true); // Установить состояние загрузки
      }
      // Диспатч создания события
      await dispatch(createLeadEvent(formData)).unwrap();

      // Диспатчи после успешного создания
      const response = await fetch('/api/sheets/deleteFromGoogleTable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          spreadsheetId: spreadsheetId,
          sheetId: 0,
          rowIndex: defaultValues.indexRow,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при удалении строки из Google Таблицы');
      }

      toast.success('Лид успешно добавлен и строка удалена');

      // await dispatch(deleteLead(defaultValues.clientId)).unwrap();
      // .then(() => {
      //   dispatch(fetchLeads());
      // });

      if (eventData.setLoading) {
        eventData.setLoading(false); // Установить состояние загрузки
      }

      dispatch(closeModal());

      console.log('modal must be closed');
    } catch (error) {
      console.error('Ошибка при создании события:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid container spacing={2}>
        {/* Тип события */}
        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            rules={{ required: 'Выберите тип события' }}
            render={(props) => (
              <Autocomplete
                options={eventTypes}
                getOptionLabel={(option) => option.label}
                value={eventTypes.find((opt) => opt.value === props.value) || null}
                onChange={(_, newVal) => {
                  props.onChange(newVal?.value || '');
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Тип события" variant="outlined" fullWidth required error={!!errors.type} />
                )}
              />
            )}
          />
        </Grid>

        {/* Дата */}
        <Grid item xs={12}>
          <Controller
            name="date"
            control={control}
            render={(props) => (
              <ReactDatePicker
                selected={props.value}
                onChange={(date) => props.onChange(date)}
                showTimeSelect
                dateFormat="Pp"
                customInput={<TextField label="Дата и время" fullWidth variant="outlined" required />}
              />
            )}
          />
        </Grid>

        {/* Ответственный */}
        <Grid item xs={12}>
          <Controller
            name="responsible"
            control={control}
            rules={{ required: 'Укажите ответственного' }}
            render={(props) => {
              const currentPerson = responsePersons.find((p) => p.value._id === props.value?._id) || null;

              return (
                <Autocomplete
                  options={responsePersons}
                  getOptionLabel={(option) =>
                    option.value?.name && option.value?.surname
                      ? `${option.value.name} ${option.value.surname}`
                      : option.title || ''
                  }
                  value={currentPerson}
                  onChange={(_, newVal: OptionItem<IUserAutocompleteOptionItem> | null) => {
                    if (newVal) {
                      props.onChange({
                        _id: newVal.value._id,
                        name: newVal.value.name,
                        surname: newVal.value.surname,
                      });
                    } else {
                      props.onChange(undefined);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Ответственный" variant="outlined" required error={!!errors.responsible?.name} />
                  )}
                />
              );
            }}
          />
        </Grid>

        {/*Создатель события*/}
        <Grid item xs={12}>
          <Controller
            name="cretor"
            control={control}
            rules={{ required: 'Создатель события' }}
            render={(props) => {
              const currentPerson = responsePersons.find((p) => p.value._id === props.value?._id) || null;

              return (
                <Autocomplete
                  options={responsePersons}
                  getOptionLabel={(option) =>
                    option.value?.name && option.value?.surname
                      ? `${option.value.name} ${option.value.surname}`
                      : option.title || ''
                  }
                  value={currentPerson}
                  onChange={(_, newVal: OptionItem<IUserAutocompleteOptionItem> | null) => {
                    if (newVal) {
                      props.onChange({
                        _id: newVal.value._id,
                        name: newVal.value.name,
                        surname: newVal.value.surname,
                      });
                    } else {
                      props.onChange(undefined);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Создатель события"
                      variant="outlined"
                      required
                      error={!!errors.responsible?.name}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        {/* Статус */}
        <Grid item xs={12}>
          <Controller
            name="status"
            control={control}
            rules={{ required: 'Выберите статус события' }}
            render={(props) => (
              <Autocomplete
                options={statuses}
                getOptionLabel={(option) => option.label}
                value={statuses.find((opt) => opt.value === props.value) || null}
                onChange={(_, newVal) => {
                  props.onChange(newVal?.value || '');
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Статус" variant="outlined" fullWidth required error={!!errors.status} />
                )}
              />
            )}
          />
        </Grid>

        {/* Комментарий */}
        <Grid item xs={12}>
          <Controller
            name="comment"
            control={control}
            render={(props) => <TextField {...props} label="Комментарий" variant="outlined" fullWidth multiline rows={3} />}
          />
        </Grid>

        {/* Клиент (только для чтения) */}
        <Grid item xs={12}>
          <Controller
            name="client"
            control={control}
            render={(props) => <TextField {...props} label="Клиент" variant="outlined" fullWidth />}
          />
        </Grid>
      </Grid>

      <Box mt={2} display="flex" justifyContent="space-between">
        <Button onClick={props.close} variant="outlined" color="secondary" disabled={isSubmitting}>
          Отмена
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          startIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
        >
          Создать
        </Button>
      </Box>
    </form>
  );
};

export default CreateLeadEventModal;
