import { createAsyncThunk } from '@reduxjs/toolkit';

import CreateLeadAPI from '@/api/create-event-lead';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';

export const FETCH_EVENTS = 'events/fetchEvents';
export const CREATE_LEAD_EVENT = 'events/createLeadEvent';
export const GET_LEAD_BY_ID = 'events/getEventById';

export const fetchLeadEvents = createAsyncThunk(FETCH_EVENTS, async (_, { rejectWithValue, dispatch }) => {
  return CreateLeadAPI.getAll()
    .then((res) => {
      return res.data.events;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка загрузки данных');
    });
});

export const createLeadEvent = createAsyncThunk(CREATE_LEAD_EVENT, async (eventData: any, { rejectWithValue, dispatch }) => {
  return CreateLeadAPI.create(eventData)
    .then((res) => {
      dispatch(showSuccessAlert('Эвент успешно создан!'));
      return res.data.lead;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка создания лида');
    });
});

export const getEventById = createAsyncThunk(GET_LEAD_BY_ID, async (id: string, { rejectWithValue, dispatch }) => {
  return CreateLeadAPI.getById(id)
    .then((res) => {
      return res.data.lead;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка загрузки данных');
    });
});
