import { IComment, IFilters, ISorting, IStatuses } from '@/types/common';
import { IClient } from 'redux/client/client.types';
import { IOffice } from 'redux/office/office.types';
import { IUser, IUserAssignee } from 'redux/user/user.types';

export const EVENTS_GET_ALL = 'EVENTS_GET_ALL';
export const EVENTS_SET_FILTERS = 'EVENTS_SET_FILTERS';
export const EVENTS_CLEAR_FILTERS = 'EVENTS_CLEAR_FILTERS';
export const EVENTS_CLEAR_SORTING = 'EVENTS_CLEAR_SORTING';
export const EVENTS_SET_SORTING = 'EVENTS_SET_SORTING';
export const EVENT_CREATE = 'EVENT_CREATE';
export const EVENT_UPDATE = 'EVENT_UPDATE';
export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_GET_CURRENT = 'EVENT_GET_CURRENT';

export enum EventTypes {
  applicant = 'applicant',
  trainee = 'trainee',
  manager = 'manager',
  seniorManager = 'seniorManager',
  topManager = 'topManager',
  salesHeadManager = 'salesHeadManager',
  regionalDirector = 'regionalDirector',
  groupOfRegionDirector = 'groupOfRegionDirector',
  developmentDirectorDeputy = 'developmentDirectorDeputy',
  developmentDirector = 'developmentDirector',
  accountant = 'accountant',
  attractionManager = 'attractionManager',
  attractionHeadManager = 'attractionHeadManager',
  HR = 'HR',
}

export type EventStatus = 'Запланировано' | 'Обработано' | 'Выполнено' | 'Просрочено';
export enum EventTypesLead {
  consultation = 'Поточка',
  marketing = 'Маркетинг',
}

export interface IEvent {
  _id: string;
  title: string;
  refill: number;
  description: string;
  clarification: string;
  deadline: Date | string;
  author: IUserAssignee;
  assignee: IUserAssignee;
  client: IClient;
  office: IOffice;
  status: EventStatus;
  comments: Array<IComment>;
  createdAt: Date | string;
  updatedAt: Date;
  isDeleted: boolean;
  completed: Date | string;
  type?: EventTypesLead;
}

export interface TransferEventsRequest {
  assignee?: Partial<IUser>;
  clients: Array<{ _id: string; client: string }>;
}
export interface CreateEventRequest {
  event: Partial<IEvent>;
  client: Partial<IClient>;
  clientIds?: Array<string>;
}
export interface IEventState {
  data: Array<IEvent>;
  status: IStatuses;
  currentEvent: IEvent | null;
  totalEvents: number;
  filters: IFilters;
  sort: ISorting;
}

export const EVENT_FILTERS = 'EVENT_FILTERS';
