import { createAsyncThunk } from '@reduxjs/toolkit';

import GoogleSheetsAPI from '@/api/google-sheets';
// import { officess_SpeadSheets } from '@/types/common';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';

export const FETCH_GOOGLE_SHEETS = 'data/fetch-google-sheets';
export const DELETE_GOOGLE_ROW_FROM_SHEETS = 'data/delete-from-google-sheets';
export const FETCH_ALL_GOOGLE_SHEETS = 'data/fetch-all-google-sheets';
export const FETCH_GOOGLE_SHEETS_BY_OFFICE = 'data/fetch-google-sheets-by-office';

type fetchGoogleSheetsType = {
  spreadsheetId: string;
  range: string;
};

// export const fetchGoogleSheets = createAsyncThunk(
//   FETCH_GOOGLE_SHEETS,
//   async (data: fetchGoogleSheetsType, { rejectWithValue, dispatch }) => {
//     return GoogleSheetsAPI.getGoogleSheets(data.spreadsheetId, data.range)
//       .then((res) => {
//         // dispatch(showSuccessAlert('Данные из Google таблицы успешно загружены!'));
//         return res.data;
//       })
//       .catch((error) => {
//         dispatch(showErrorAlert(error?.response?.data));
//         return rejectWithValue(error?.response?.data?.message || 'Ошибка загрузки данных');
//       });
//   },
// );

export const fetchGoogleSheetsByOffice = createAsyncThunk(
  FETCH_GOOGLE_SHEETS_BY_OFFICE,
  async ({ officeName, range }: { officeName: string; range: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await GoogleSheetsAPI.getSheetsByOffice(officeName, range);
      return response.data;
    } catch (error) {
      //@ts-ignore
      const message = error?.response?.data?.message || 'Ошибка загрузки данных';
      dispatch(showErrorAlert(message));
      return rejectWithValue(message);
    }
  },
);

//TODO ЭТО НУЖНО ДОРАБОТАТЬ ПОСЛЕ КАК НА БЭКЕ БУДЕТ ИСПРАВЛЕН МЕТОД
export const fetchAllGoogleSheets = createAsyncThunk(FETCH_ALL_GOOGLE_SHEETS, async (_, { rejectWithValue, dispatch }) => {
  try {
    // 🚀 Делаем запрос к бэку, который сам получает все данные
    const response = await GoogleSheetsAPI.getAllSheets();

    return response.data; // ✅ Возвращаем массив { officeName, data }
  } catch (error) {
    // @ts-ignore
    dispatch(showErrorAlert(error?.response?.data));

    // @ts-ignore
    return rejectWithValue(error?.response?.data?.message || 'Ошибка загрузки всех Google таблиц');
  }
});

export const deleteRowFromGoogleSheets = createAsyncThunk(
  DELETE_GOOGLE_ROW_FROM_SHEETS,
  async (googleData: { officeName: string; sheetId: number; rowIndex: number | null }, { rejectWithValue, dispatch }) => {
    return GoogleSheetsAPI.deleteRowFromGoogleSheets(googleData)
      .then((res) => {
        dispatch(showSuccessAlert('Лид успешно удален из Google таблицы'));
        return res.data.message;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data?.message || 'Ошибка создания лида');
      });
  },
);
